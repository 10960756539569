import dayjs from '@/lib/dayjs'
import { lineColumnChartOptions } from '@/config/dashboard'
import {
  getChartData,
  truncateDecimal,
  getDateArray,
  getDateOfRange,
} from '@/utils/dashboard'
import { getCssVar } from '@/utils/helper'

const dayFormat = (date, dateFormat) => {
  return dayjs(date).format(dateFormat)
}

const yaxisTitleConfig = {
  sumAmount: '金額',
  count: '單數',
  avgAmount: '金額',
  avgMemberCount: '單數',
}

export const generateRepurchaseConfig = (
  labels = [],
  series = [],
  yaxisTitle,
) => ({
  ...lineColumnChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
    getCssVar('chart-6'),
  ],
  xaxis: {
    type: 'date',
    title: {
      text: '日期',
    },
  },
  yaxis: [
    {
      showAlways: true,
      opposite: true,
      title: {
        text: '人數',
      },
    },
    {
      showAlways: true,
      title: {
        text: yaxisTitle,
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

const initDataSet = (labels, typeKeys) => {
  const obj = {}
  typeKeys.forEach((item) => {
    obj[item] = {
      name: item,
      type: 'line',
      data: labels.map(() => 0),
    }
  })
  return obj
}

export const getRepurchaseData = async ({
  shopId,
  interval,
  splitType,
  target,
  typeKeys,
}) => {
  const dateFormat = 'YYYY/MM/DD'

  const dateRange = getDateOfRange({
    interval,
    splitType,
  })
  const { result, lastUpdatedAt } = await getChartData({
    shopId,
    key: 'transactionTrendByType',
    dateTrunc: splitType,
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
    isRepurchase: '回購',
  })

  const dataset = initDataSet(dateRange.dateArray, typeKeys)

  const data = result.map((item) => {
    return {
      ...item,
      createdAt: dayFormat(item.createdAt, dateFormat),
      avgAmount: truncateDecimal(item.avgAmount),
      avgMemberCount: truncateDecimal(item.avgMemberCount),
    }
  })

  data.forEach((item) => {
    const dateIndex = dateRange.dateArray.findIndex(
      (date) => date === item.createdAt,
    )
    if (dataset[item.type]) {
      dataset[item.type].data[dateIndex] += item[target]
    }
  })

  const series = Object.values(dataset)

  //  最後把人數作加總
  series.unshift({
    name: '全渠道人數',
    type: 'column',
    data: dateRange.dateArray.map((item) => {
      return data.reduce((prev, curr) => {
        if (curr.createdAt === item) {
          return prev + curr.memberCount
        }
        return prev
      }, 0)
    }),
  })

  const yaxisTitle = yaxisTitleConfig[target]

  return {
    newOptions: generateRepurchaseConfig(
      dateRange.dateArray,
      series,
      yaxisTitle,
    ),
    lastUpdatedAt,
  }
}
